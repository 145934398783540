<template>
<div class="about-page w-full h-screen">
  <div class="spn pt-6 mt-6" v-if="position === 8 && !activeDevis">
    <div class="w-full flex justify-center items-center h-32">
      <spinner />
    </div>

    <div class="mt-10 text-center textCharge">
      Calcul de la prime...
    </div>
  </div>

  <div class="spn" v-if="position === 8 && activeDevis">
    <div class="w-full flex justify-center items-center h-full">
      <devis-icons />
    </div>
  </div>

  <div class="resultat md:pl-10 md:pr-10">
    <resultatPrime v-if="position === 9" type="Auto" status="prorogation" @retour="retourDevis"/>
  </div>

</div>
</template>

<script>
import allQuiz from '@/components/cedro/automobile/allQuiz.json'
import spinner from '@/components/helper/add/spinner'
import devisIcons from '@/components/helper/add/devisIcons'
import resultatPrime from '@/components/cedro/devis/devisTemplate'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'index',
  components: {
    spinner,
    devisIcons,
    resultatPrime
  },
  props: {
    width: {
      type: String,
      default: '30%'
    },
    retour: {
      type: Number,
      default: null
    },
    suivant: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      position: 8,
      allQuiz,
      temoinR: 0,
      temoinS: 0,
      activeDevis: false,
      responses: {
        type: 'Auto'
      },
      error: null,
      yetError: null,
      totalPourcent: '0%',
      preliminaire: null
    }
  },

  computed: {
  },

  mounted () {
    this.devis = this.$store.getters.souscriptionDetail
    // console.log(this.devis)
    this.devisRequest()
  },

  methods: {

    retourDevis (answer) {
      this.position = 7
      setTimeout(() => {
        this.error = null
      }, 200)
    },

    devisRequest () {
      const old = true
      let garantie = 'IndividuelleConducteur'
      const operation = 'extend'
      const zone = this.devis.meta.location.zone
      const statusSocial = this.devis.meta.social.status
      const classe = 'b'

      if (this.devis.meta.protectConductor) {
        garantie = 'ProtectionChauffeur'
      }

      let fuel = 'e'
      if (this.devis.meta.isDieselFuel) {
        fuel = 'd'
      }

      const body = {
        power: this.devis.meta.fiscalPower,
        period: this.devis.duration,
        guaranties: garantie,
        operation: operation,
        fuel: fuel,
        zone: zone,
        social: classe,
        status: statusSocial,
        hasOldCertificate: old
      }

      http.post(apiroutes.baseURL + apiroutes.primeAuto, body)
        .then(response => {
          console.log(response.data)
          const lastSave = {
            devis: response.data,
            createdAt: new Date().getTime(),
            id: 'DV-00' + (this.$store.getters.allDevis.length + 1),
            oldAssur: 'Oui'
          }

          this.$store.dispatch('saveFirstDevis', lastSave)
          // setTimeout(() => {
          this.activeDevis = true
          setTimeout(() => {
            this.position = 9
            this.activeDevis = false
            this.$emit('steep', 'resultat')
          }, 500)
          // }, 5000)
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.barx{
  width: 95%;
}
.spn{
  width: 100%;
  height: 400px;
}

  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .textCharge{
    font-weight: bold;
    font-size: 28px;
    line-height: 153%;
    color: #263238;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
  .error{
    color: #e36767;
    font-size: 18px;
    line-height: 32px;
  }

  @media screen and (max-width: 1024px) and (min-width: 770px){
    .about-page {
      width: 70%;
      padding-bottom: 200px;
    }
  }

  @media screen and (max-width: 769px) and (min-width: 701px){
    .about-page {
      width: 90%;
      padding-bottom: 200px;
    }
  }

  @media screen and (max-width: 700px) {
    .about-page {
      width: 90%;
      padding-bottom: 200px;
    }
    .titre{
      font-size: 24px;
      line-height: 32px;
    }
    .textCharge{
      font-size: 20px;
    }
  }

</style>
